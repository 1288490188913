<template>
    <div>
         <div class="secondHeader">
            <div class="inputGroup">
                <!-- todo 搜索栏格式 -->
                <el-input placeholder="输入投稿标题关键字进行搜索"
                          v-model="searchData"
                          prefix-icon="el-icon-search">
                </el-input>

                <el-button type="text" @click="search(false)">搜索</el-button>
            </div>
            <div class="buttonGroup">
                <el-button plain @click="visible=true">创建任务</el-button>
            </div>
         </div>
         <div>
            <el-table  :data="missions"
                        stripe
                        style="width:100%"
                        v-loading="table_loading"              
            >
                <el-table-column prop="title" label="任务标题"></el-table-column>
                <el-table-column label="任务状态">
                                     <!-- :filters="[
                                        {text:'已完成',value:'finished'},
                                        {text:'进行中',value:'doing'}
                                    ]"
                                    filter-method="filter" -->
                    <template slot-scope="scope">
                        <el-tag :type="global.STATES[scope.row.statusCode].type">{{global.STATES[scope.row.statusCode].label}}</el-tag>
                    </template>
                </el-table-column>
                <el-table-column prop="startTime" label="开始时间"></el-table-column>
                <el-table-column prop="endTime" label="结束时间"></el-table-column>
                <el-table-column label="完成数/未完成数">
                    <template slot-scope="scope">
                        <span>{{scope.row.commitCount}}/{{scope.row.unCommitCount}}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="operation" label="操作">
                    <template slot-scope="scope">
                        <el-link type="primary" :underline="false" @click="findDetail(scope.row)">详情</el-link>
                        <el-divider direction="vertical"></el-divider>
                        <el-link type="primary" :underline="false" @click="deleteMission(scope.row.taskId)">删除</el-link>
                    </template>
                </el-table-column>
             </el-table>
             <el-pagination layout="prev,pager,next"
                            :page-size.sync="global.PAGE_SIZE"
                            :total="total"
                            @current-change="handleCurrentChange"
                            :current-page.sync="currentPage">
             </el-pagination>
         </div>
         <el-dialog :visible.sync="visible" title="创建任务" class="container">
             <el-form :model="form" :rules="rules" ref="form" label-width="120px">
                 <el-form-item label="任务标题：" prop="title" class="form-item">
                     <el-input v-model="form.title"></el-input>
                 </el-form-item>
                 <el-form-item label="任务类型：" class="form-item">
                     <template>
                         <el-radio disabled v-model="form.type" label="0">网评任务</el-radio>
                         <el-radio disabled v-model="form.type" label="1">投稿任务</el-radio>
                     </template>
                 </el-form-item>
                 <el-form-item label="(可选)任务链接：" class="form-item">
                     <el-input v-model="form.taskUrl"></el-input>
                 </el-form-item>
                 <el-form-item label="任务描述：" prop="description" class="form-item">
                     <el-input type="textarea"
                                :row="3"
                                placeholder="请输入任务描述"
                                v-model="form.description">
                     </el-input>
                 </el-form-item>
                 <el-form-item label="负责队伍：" prop="targetDepartmentIdList" class="form-item">
                     <el-cascader placeholder="负责队伍"
                                  v-model="form.targetDepartmentIdList"
                                  :options="departments"
                                  :props="{multiple:true}"
                                  clearable
                                  collapse-tags>
                     </el-cascader>
                 </el-form-item>
                 <el-form-item label="起止时间：" prop="datetimeRange" class="form-item">
                    <el-date-picker v-model="form.datetimeRange"
                        type="datetimerange"
                        range-separator="-"
                        start-placeholder="选择起始时间"
                        end-placeholder="选择终止时间"
                        value-format="yyyy-MM-dd hh:mm:ss">
                    </el-date-picker>
                 </el-form-item>
                 <el-form-item label="选择封面：" prop="coverUrl" class="form-item">
                    <el-upload
                        class="avatar-uploader"
                        action=""
                        :http-request="coverUpload"
                        :limit="1"
                        list-type="picture-card"
                        :before-upload="beforeCoverUpload"
                        :on-preview="handlePreview"
                        :on-remove="handleRemove"                
                    >
                        <i v-if="form.coverUrl!=null" class="el-icon-plus avatar-uploader-icon"></i>
                    </el-upload>
                    <el-dialog :visible.sync="disabled" append-to-body>
                        <img width="100%" :src="form.coverUrl">
                    </el-dialog>
                 </el-form-item>
                 <div class="button-group">
                     <el-button type="primary" @click="submitCreateMission('form')">创建任务</el-button>
                     <el-button @click="visible=false">取消</el-button>
                 </div>
             </el-form>
         </el-dialog>
    </div>
</template>

<script>
export default {
  name: "MissionTable",
  data() {
    return {
      searchData: "",
      departments: [],
      //------ 表格相关数据 -------
      missions: [],

      currentPage: 1,
      total: undefined,
      //------ 表单相关数据 --------
      visible: false,
      rules: {
        title: [
          { required: true, message: "请输入任务名称", trigger: "blur" }
        ],
        description: [
          { required: true, message: "请输入任务描述", trigger: "blur" }
        ],
        datetimeRange: [
          { required: true, message: "请选择起止时间", trigger: "change" }
        ],
        targetDepartmentIdList: [
          { required: true, message: "请选择负责队伍", trigger: "change" }
        ]
      },
      form: {
        title: "",
        type: "0",
        targetDepartmentIdList: [],
        description: "",
        datetimeRange: "",
        coverUrl: ""
      },
      disabled: false,
      table_loading: false,
      dialog_loading: false
    };
  },
  mounted() {
    //获取部门数据并处理
    this.departments = JSON.parse(sessionStorage.getItem("departments"));
    //console.log("departments",this.departments)
    this.search(false);
  },
  methods: {
    handleCurrentChange() {
      this.search(true);
    },
    //查询任务
    search(isCurrentChange) {
      this.table_loading = true;
      this.$axios
        .post("/api/task/web/list", {
          keyWord: this.searchData,
          pageNo: this.currentPage - 1,
          pageSize: 10,
          type: "NORMAL_TASK"
        })
        .then(res => {
          this.missions = res.data.data.content;
          if (!isCurrentChange) this.currentPage = 1;
          this.total = res.data.data.totalElements;
        })
        // .catch(err => {
        //   this.$message.error(err.response.data.message);
        //   console.log(err.response);
        //   this.table_loading = false;
        // })
        .finally(()=>{
          this.table_loading = false;
        })
    },
    beforeCoverUpload(file) {
      const IMG_TYPE = ["image/jpg", "image/jpeg", "image/png"];
      const isImg = IMG_TYPE.includes(file.type);
      const size = file.size / 1024 / 1024 < 10;

      if (!isImg) {
        this.$message.error("请选择图片作为头像!");
      }
      if (!size) {
        this.$message.error("上传头像图片大小不能超过10MB!");
      }
      console.log(isImg && size);
      return isImg && size;
    },
    coverUpload(file) {
      console.log("upload", file);
      let formData = new FormData();
      formData.append("file", file.file);
      this.$axios
        .post("/api/file/upload", formData)
        .then(res => {
          console.log("res", res);
          this.form.coverUrl = res.data.data;
          this.$message.success("图片上传成功");
        })
        // todo
        // .catch(err => {
        //   console.log("err", err.response);
        //   this.$message.error("上传失败");
        // });
        
    },
    handlePreview() {
      this.disabled = true;
    },
    handleRemove() {
      this.form.coverUrl = "";
    },
    submitCreateMission(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.dialog_loading = true;
          let formattedTargetDepartmentIdList = [];
          for (let item of this.form.targetDepartmentIdList)
            formattedTargetDepartmentIdList.push(item[1]);
          console.log(formattedTargetDepartmentIdList)
          this.$axios
            .post("/api/task/create", {
              creatorId: JSON.parse(sessionStorage.getItem("userInfo")).userId,
              description: this.form.description,
              startTime: this.form.datetimeRange[0],
              endTime: this.form.datetimeRange[1],
              targetDepartmentIdList: formattedTargetDepartmentIdList,
              title: this.form.title,
              type: "NORMAL_TASK",
              coverUrl: this.form.coverUrl,
              taskUrl:this.form.taskUrl
            })
            .then(res => {
              console.log(res.data);

              this.$message.success("创建成功");
              if (this.currentPage != 1 || this.searchData != "") {
                this.currentPage = 1;
                this.searchData = "";
                this.search(false);
              } else {
                this.missions.unshift(res.data.data);
                this.total++;
                if (this.total > 10) this.missions.pop();
              }
              this.dialog_loading = false;
              this.visible = false;
              this.form.type = "0";
            });
        } else {
          this.$message({
            message: "表单填写不规范",
            type: "error"
          });
        }
      });
    },
    cancelForm(formName) {
      this.$refs[formName].resetFields();
      this.form.coverUrl = "";
    },
    findDetail(missionBasic) {
      this.$router.push({
        name: "MissionDetail",
        params: {
          taskId: missionBasic.taskId
        }
      });
    },
    deleteMission(taskId) {
      console.log(taskId);
      this.$confirm('删除任务将会连带删除网评员已经提交的任务信息，是否删除？','提示',{
          confirmButtonText:'确定',
          cancelButtonText:'取消',
          type:'warning'
      }).then(()=>{
          this.$axios.delete(`/api/task/${taskId}`).then((res)=>{
            console.log(res.data);
            if (res.data.code === 200) {
              this.$message({
                  type:'success',
                  message:'删除成功！'
              });
              this.search(false);
            }
          })
      })
    },
  },
  watch: {
    //只要关掉dialog则清空表单
    visible() {
      if (this.visible == false) {
        this.cancelForm("form");
      }
    },
  }
};
</script>

<style src='../../../assets/css/secondHeader.css' scoped>
</style>
<style src='../../../assets/css/table.css' scoped>
</style>
<style src='../../../assets/css/dialog.css' scoped>
</style>
